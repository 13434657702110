export class DateTimeHelper {
    public static toDateDisplay = (dateString: string) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("de-DE", { day: "numeric", month: "long", year: "numeric" }).format(date);
    };
    public static compareDates = (date1: string, date2: string) => {
        const dateA = new Date(date1);
        const dateB = new Date(date2);
        return dateA > dateB ? 1 : -1;
    };
    public static compareDatesTimes = (date1: string, time1: string, date2: string, time2: string) => {
        const dateA = new Date(`${date1}T${time1}`);
        const dateB = new Date(`${date2}T${time2}`);
        return dateA > dateB ? 1 : -1;
    };
}
