import React, { useEffect, useState, FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import { CardActions, Button, TextField, Box } from "@mui/material";
import { UserStore } from "../stores";
import { registerWithEmailAndPassword } from "../base";
import { LoginCard } from "../hoc";

const useStyles = makeStyles(() =>
    createStyles({
        inputBox: {
            padding: "8px",
            display: "flex",
            "flex-direction": "column"
        }
    })
);

const RegisterPageContainer: FunctionComponent = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const userState = UserStore((state) => state.user);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const register = () => {
        if (!name) alert("Please enter name");
        registerWithEmailAndPassword(name, email, password);
    };

    useEffect(() => {
        console.log("check user");
        if (userState) {
            navigate("/home");
        }
    }, [userState, navigate]);

    return (
        <LoginCard>
            <Box
                component="form"
                noValidate
                className={classes.inputBox}
                autoComplete="off">
                <TextField
                    margin="dense"
                    label="Full Name"
                    variant="outlined"
                    color="secondary"
                    sx={{
                        input: { color: "#3e3e3e" },
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#32746d"
                            }
                        }
                    }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="E-Mail"
                    variant="outlined"
                    color="secondary"
                    sx={{
                        input: { color: "#3e3e3e" },
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#32746d"
                            }
                        }
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="dense"
                    variant="outlined"
                    color="secondary"
                    sx={{
                        input: { color: "#3e3e3e" },
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#32746d"
                            }
                        }
                    }}
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Box>
            <CardActions>
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => register()}>
                    Register
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate("/")}
                    size="small">
                    to Login
                </Button>
            </CardActions>
        </LoginCard>
    );
};

export default RegisterPageContainer;
