import React, { FunctionComponent, useEffect } from "react";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { PageContainer } from "../hoc";
import { VacationStore, UserStore } from "../stores";
import { VacationEditForm } from "../components";

const VacationEditContainer: FunctionComponent = () => {
    const { vacationId } = useParams();
    const user = UserStore((state) => state.user);
    const selectedVacation = VacationStore((state) => state.selectedVacation);
    const createNewVacation = VacationStore((state) => state.createNewVacation);
    const saveVacation = VacationStore((state) => state.saveVacation);
    const updateVacation = VacationStore((state) => state.updateVacation);
    const getVacationOverview = VacationStore((state) => state.getVacationOverview);
    const getVacation = VacationStore((state) => state.getVacation);
    const resetVacation = VacationStore((state) => state.resetVacation);

    useEffect(() => {
        if (vacationId && user) {
            getVacation(user.uid, vacationId);
        } else {
            createNewVacation();
        }
    }, [vacationId, user, createNewVacation, getVacation]);

    useEffect(() => {
        return () => {
            resetVacation();
        };
    }, [resetVacation]);

    return (
        <PageContainer>
            <Typography
                variant="h3"
                mt={2}
                mb={2}
                color="text.primary">
                Urlaub erstellen
            </Typography>
            <VacationEditForm
                user={user}
                vacationId={vacationId}
                selectedVacation={selectedVacation}
                getVacationOverview={getVacationOverview}
                saveVacation={saveVacation}
                updateVacation={updateVacation}
            />
        </PageContainer>
    );
};

export default VacationEditContainer;
