import React, { FunctionComponent, useState, useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Vacation, VacationEditFormModelProps } from "../models";

const VacationEditForm: FunctionComponent<VacationEditFormModelProps> = ({
    user,
    selectedVacation,
    vacationId,
    updateVacation,
    saveVacation,
    getVacationOverview
}) => {
    const navigate = useNavigate();

    const [destination, setDestination] = useState(selectedVacation?.destination);
    const [startDate, setStartDate] = useState(selectedVacation?.startDate);
    const [endDate, setEndDate] = useState(selectedVacation?.endDate);
    const [imageUrl, setImageUrl] = useState(selectedVacation?.imageUrl ?? "");

    useEffect(() => {
        if (selectedVacation) {
            setDestination(selectedVacation?.destination);
            setStartDate(selectedVacation?.startDate);
            setEndDate(selectedVacation?.endDate);
            setImageUrl(selectedVacation?.imageUrl ?? "");
        }
        return () => {
            setDestination("");
            setStartDate("");
            setEndDate("");
            setImageUrl("");
        };
    }, [selectedVacation]);

    const createVacation = async () => {
        if (user && selectedVacation) {
            const vacation: Vacation = {
                startDate: startDate ?? selectedVacation.startDate,
                endDate: endDate ?? selectedVacation.endDate,
                destination: destination ?? selectedVacation.destination,
                imageUrl: imageUrl
            };
            if (vacationId) {
                await updateVacation(user.uid, vacation, vacationId);
            } else {
                await saveVacation(user.uid, vacation);
            }
            await getVacationOverview(user.uid);
            navigate("/");
        } else {
            console.error("no user or selected Vacation");
        }
    };

    return (
        <>
            <TextField
                margin="normal"
                label="Urlaubsort"
                variant="outlined"
                fullWidth
                value={destination}
                InputLabelProps={{
                    style: { color: "#fff" }
                }}
                onChange={(e) => setDestination(e.target.value)}
            />
            <TextField
                margin="normal"
                label="Start Datum"
                variant="outlined"
                type="date"
                fullWidth
                value={startDate}
                InputLabelProps={{
                    style: { color: "#fff" }
                }}
                onChange={(e) => setStartDate(e.target.value)}
            />
            <TextField
                margin="normal"
                label="End Datum"
                variant="outlined"
                type="date"
                fullWidth
                value={endDate}
                InputLabelProps={{
                    style: { color: "#fff" }
                }}
                onChange={(e) => setEndDate(e.target.value)}
            />
            <TextField
                margin="normal"
                label="Bild URL"
                variant="outlined"
                fullWidth
                value={imageUrl}
                InputLabelProps={{
                    style: { color: "#fff" }
                }}
                onChange={(e) => setImageUrl(e.target.value)}
            />
            <Box
                mt={2}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end">
                <Button
                    variant="contained"
                    onClick={() => createVacation()}>
                    {vacationId ? "Speichern" : "Erstellen"}
                </Button>
            </Box>
        </>
    );
};

export default VacationEditForm;
