import create from "zustand";
import { Vacation } from "../models";
import VacationService from "../services/VacationService";

interface State {
    selectedVacation: Vacation | null;
    vacationOverview: Vacation[] | null;
    loadingVacations: boolean;
    loadingVacation: boolean;
    savingVacation: boolean;
    createNewVacation: () => void;
    getVacationOverview: (uid: string) => Promise<any>;
    getVacation: (uid: string, vacationId: string) => Promise<any>;
    saveVacation: (uid: string, vacation: Vacation) => Promise<any>;
    updateVacation: (uid: string, vacation: Vacation, vacationId: string) => Promise<any>;
    resetVacation: () => void;
    resetVacationOverview: () => void;
}

const initVacation = {
    id: "init",
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
    destination: ""
};

const useStore = create<State>((set) => ({
    selectedVacation: { ...initVacation },
    vacationOverview: null,
    loadingVacations: false,
    loadingVacation: false,
    savingVacation: false,
    createNewVacation: () => set(() => ({ selectedVacation: { ...initVacation } })),
    getVacationOverview: async (uid: string): Promise<any> => {
        let vacations = await VacationService.getVacationOverview(uid);
        if (vacations) {
            vacations = Object.entries(vacations).map((vacation) => {
                return { ...vacation[1], id: vacation[0] };
            });
        } else {
            vacations = [];
        }
        set(() => ({ vacationOverview: vacations }));
    },
    getVacation: async (uid: string, vacationId: string): Promise<any> => {
        let vacation = await VacationService.getVacationById(uid, vacationId);
        if (vacation) {
            vacation = { ...vacation, id: vacationId };
        } else {
            vacation = { ...initVacation };
        }
        set(() => ({ selectedVacation: vacation }));
    },
    saveVacation: (uid: string, vacation: Vacation) => VacationService.postVacation(uid, vacation),
    updateVacation: (uid: string, vacation: Vacation, vacationId: string) => VacationService.updateVacation(uid, vacation, vacationId),
    resetVacation: () => set({ selectedVacation: null }),
    resetVacationOverview: () => set({ vacationOverview: null })
}));

export const VacationStore = useStore;
