import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteModel {
    user: any;
    loading: boolean;
    children: any;
}

const ProtectedRoute: any = ({ user, loading, children }: ProtectedRouteModel) => {
    if (!user && !loading) {
        return (
            <Navigate
                to="/"
                replace
            />
        );
    }
    return children;
};

export default ProtectedRoute;
