import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Stack, Fab, Typography } from "@mui/material";
import { DatelineModelProps } from "../models";
import { DateTimeHelper } from "../helpers";

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            color: "black"
        }
    })
);

const Dateline: FunctionComponent<DatelineModelProps> = ({ dates, selectedDate, setDate }) => {
    const classes = useStyles();

    return (
        <Stack
            direction="row"
            spacing={2}
            mb={2}
            className={classes.card}>
            {dates.sort(DateTimeHelper.compareDates).map((dateString) => {
                const date = new Date(dateString);
                const month = new Intl.DateTimeFormat("de-DE", { month: "short" }).format(date);
                const weekend = [0, 6].includes(date.getDay()) ? 1 : 0;
                return (
                    <Fab
                        style={{ display: "block", textAlign: "center" }}
                        sx={{ bgcolor: selectedDate === dateString ? "secondary.main" : "default" }}
                        onClick={() => setDate(dateString)}
                        key={date.toISOString()}>
                        <Typography
                            variant="subtitle1"
                            mt={0}
                            mb={-1}
                            fontWeight={weekend ? "700" : "400"}
                            color={selectedDate === dateString ? "text.primary" : "text.secondary"}>
                            {date.getDate()}.
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            mt={0}
                            mb={0}
                            fontWeight={weekend ? "700" : "400"}
                            color={selectedDate === dateString ? "text.primary" : "text.secondary"}>
                            {month}
                        </Typography>
                    </Fab>
                );
            })}
        </Stack>
    );
};

export default Dateline;
