import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material";

// A custom theme for this app
const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === "outlined" &&
                        ownerState.color === "primary" && {
                            borderColor: "#fff",
                            color: "#fff"
                        })
                })
            }
        }
    },
    palette: {
        primary: {
            main: "#32746d",
            light: "#9ec5ab",
            dark: "#104f55"
        },
        secondary: {
            main: "#01200f",
            light: "#5e8c61",
            dark: "#011502"
        },
        error: {
            main: red.A400
        },
        background: {
            default: "#ffcf00"
        },
        text: {
            primary: "#fff",
            secondary: "#3e3e3e"
        }
    }
});

export default theme;
