import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, CardMedia, CardActions, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ActionViewCardModelProps } from "../models";
import {
    FerryActionViewCard,
    FlightActionViewCard,
    HotelActionViewCard,
    DriveActionViewCard,
    HikeActionViewCard,
    RestaurantActionViewCard,
    SightseeingActionViewCard
} from ".";

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }
    })
);

const ActionViewCard: FunctionComponent<ActionViewCardModelProps> = ({ activity, setSelectedActivity, setActivity, setOpenModal, setOpenDeleteModal }) => {
    const classes = useStyles();

    const handleDelete = () => {
        setSelectedActivity(activity);
        setOpenDeleteModal(true);
    };

    const handleEdit = () => {
        setSelectedActivity(activity);
        setActivity(activity.type);
        setOpenModal(true);
    };

    return (
        <Card
            variant="outlined"
            className={classes.card}>
            {activity.imageUrl !== "" && (
                <CardMedia
                    component="img"
                    height="140"
                    image={activity.imageUrl}
                    alt="activity image"
                />
            )}
            {activity.type === "ferry" && <FerryActionViewCard activity={activity} />}
            {activity.type === "flight" && <FlightActionViewCard activity={activity} />}
            {activity.type === "hotel" && <HotelActionViewCard activity={activity} />}
            {activity.type === "drive" && <DriveActionViewCard activity={activity} />}
            {activity.type === "hike" && <HikeActionViewCard activity={activity} />}
            {activity.type === "restaurant" && <RestaurantActionViewCard activity={activity} />}
            {activity.type === "sightseeing" && <SightseeingActionViewCard activity={activity} />}
            <CardActions
                sx={{ paddingTop: 0 }}
                disableSpacing>
                <IconButton
                    onClick={handleEdit}
                    aria-label="edit">
                    <EditIcon />
                </IconButton>
                <IconButton
                    onClick={handleDelete}
                    aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

export default ActionViewCard;
