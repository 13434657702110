import React, { FunctionComponent } from "react";
import { CardContent, Typography } from "@mui/material";
import { DetailActionViewCardModelProps } from "../../models";
import { DateTimeHelper } from "../../helpers";

const FerryActionViewCard: FunctionComponent<DetailActionViewCardModelProps> = ({ activity }) => {
    return (
        <CardContent sx={{ paddingBottom: "8px" }}>
            <Typography
                variant="h6"
                sx={{ marginBottom: "8px", lineHeight: "1.2" }}
                color="text.secondary">
                {activity.title}
            </Typography>
            <Typography
                variant="body1"
                color="text.secondary">
                {DateTimeHelper.toDateDisplay(activity.date)}, {activity.time} Uhr
            </Typography>
            {activity.start !== "" && activity.destination !== "" && (
                <Typography
                    variant="body1"
                    color="text.secondary">
                    {activity.start} - {activity.destination} {activity.duration !== "" ? `(${activity.duration})` : ""}
                </Typography>
            )}
            {activity.price !== "" && (
                <Typography
                    variant="body1"
                    color="text.secondary">
                    {activity.price}
                </Typography>
            )}
            {activity.link !== "" && (
                <Typography
                    variant="body2"
                    color="text.secondary">
                    <a
                        href={activity.link}
                        rel="noreferrer"
                        target="_blank">
                        Mehr Informationen
                    </a>
                </Typography>
            )}
        </CardContent>
    );
};

export default FerryActionViewCard;
