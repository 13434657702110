import React, { FunctionComponent, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { VacationStore, UserStore } from "../stores";
import { Vacation } from "../models";

const VacationCurrentContainer: FunctionComponent = () => {
    const vacationOverview = VacationStore((state) => state.vacationOverview);
    const getVacationOverview = VacationStore((state) => state.getVacationOverview);
    const resetVacationOverview = VacationStore((state) => state.resetVacationOverview);
    const userState = UserStore((state) => state.user);
    const [currentVacation, setCurrentVacation] = useState<Vacation[] | undefined>(undefined);

    useEffect(() => {
        if (vacationOverview === null && userState) {
            getVacationOverview(userState.uid);
        } else if (vacationOverview) {
            const today = new Date();
            const tempCurrentVacation: Vacation[] = [];
            vacationOverview.forEach((vacation) => {
                const vacationEnd = new Date(vacation.endDate);
                const vacationStart = new Date(vacation.startDate);
                if (vacationEnd > today && vacationStart < today) {
                    tempCurrentVacation.push(vacation);
                }
                setCurrentVacation(tempCurrentVacation);
            });
        }
    }, [vacationOverview, userState, getVacationOverview]);

    useEffect(() => {
        return () => {
            resetVacationOverview();
        };
    }, [resetVacationOverview]);

    return (
        <>
            {currentVacation && currentVacation.length > 0 && (
                <Navigate
                    to={`/vacation/${currentVacation[0].id}`}
                    replace
                />
            )}
            {currentVacation?.length === 0 && (
                <Navigate
                    to={`/home`}
                    replace
                />
            )}
        </>
    );
};

export default VacationCurrentContainer;
