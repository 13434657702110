import React, { FunctionComponent, useState } from "react";
import { CardHeader, CardActions, Button, CardContent, TextField } from "@mui/material";
import { DetailActionEditCardModelProps, Activity } from "../../models";

const FlightActionEditCard: FunctionComponent<DetailActionEditCardModelProps> = ({
    selectedVacation,
    selectedActivity,
    userId,
    selectedDate,
    handleCloseModal,
    saveActivity,
    updateActivity,
    getActivityOverview
}) => {
    const [title, setTitle] = useState(selectedActivity?.title ?? "");
    const [date, setDate] = useState(selectedActivity?.date ?? selectedDate);
    const [time, setTime] = useState(selectedActivity?.time ?? "12:00");
    const [start, setStart] = useState(selectedActivity?.start ?? "");
    const [destination, setDestination] = useState(selectedActivity?.destination ?? "");
    const [link, setLink] = useState(selectedActivity?.link ?? "");
    const [imageUrl, setImageUrl] = useState(selectedActivity?.imageUrl ?? "");
    const [duration, setDuration] = useState(selectedActivity?.duration ?? "");

    const inputSx = {
        input: { color: "#3e3e3e" },
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
                borderColor: "#32746d"
            }
        }
    };

    const handleSave = async () => {
        const activity: Activity = {
            type: "flight",
            title: title,
            date: date,
            time: time,
            start: start,
            destination: destination,
            link: link,
            imageUrl: imageUrl,
            duration: duration
        };
        if (selectedActivity?.id) {
            await updateActivity(userId, selectedVacation.id ?? "0", selectedActivity?.id ?? "0", activity);
        } else {
            await saveActivity(userId, selectedVacation.id ?? "0", activity);
        }
        handleCloseModal();
        getActivityOverview(userId, selectedVacation.id ?? "0");
    };

    return (
        <>
            <CardHeader
                titleTypographyProps={{ color: "text.secondary" }}
                title="Flug"
            />
            <CardContent>
                <TextField
                    margin="dense"
                    label="Name"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Datum"
                    type="date"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Uhrzeit"
                    type="time"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Start"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Ziel"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={destination}
                    onChange={(e) => setDestination(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Link"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Bild URL"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Dauer"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    sx={inputSx}
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                />
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    size="small">
                    Speichern
                </Button>
                <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={handleCloseModal}>
                    Schließen
                </Button>
            </CardActions>
        </>
    );
};

export default FlightActionEditCard;
