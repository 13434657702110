import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, CardHeader } from "@mui/material";

interface LoginCardModel {
    children: any;
}

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            width: "60vw",
            "min-width": "280px",
            "max-width": "400px"
        },
        login_container: {
            display: "flex",
            "align-items": "center",
            "flex-direction": "column",
            "margin-top": "50px"
        }
    })
);

const LoginCard: any = ({ children }: LoginCardModel) => {
    const classes = useStyles();

    return (
        <div className={classes.login_container}>
            <Card
                variant="outlined"
                className={classes.card}>
                <CardHeader
                    titleTypographyProps={{ color: "text.secondary" }}
                    title="Vacation Planner"
                />
                {children}
            </Card>
        </div>
    );
};

export default LoginCard;
