import React, { FunctionComponent, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
    HomePageContainer,
    LoginPageContainer,
    RegisterPageContainer,
    ResetPageContainer,
    VacationEditContainer,
    VacationDetailContainer,
    VacationCurrentContainer
} from "../containers";
import { auth } from "../base";
import { UserStore } from "../stores";
import { ProtectedRoute } from "../hoc";
/**
 * App router
 *
 */

const AppRouter: FunctionComponent<any> = () => {
    const userState = UserStore((state) => state.user);
    const setUser = UserStore((state) => state.setUser);
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (!userState) {
            if (user) {
                setUser(user);
            }
        }
    }, [user, userState, setUser]);

    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={<LoginPageContainer />}
                />
                <Route
                    path="/register"
                    element={<RegisterPageContainer />}
                />
                <Route
                    path="/reset"
                    element={<ResetPageContainer />}
                />
                <Route
                    path="/home"
                    element={
                        <ProtectedRoute
                            user={user}
                            loading={loading}>
                            <HomePageContainer />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/editVacation"
                    element={
                        <ProtectedRoute
                            user={user}
                            loading={loading}>
                            <VacationEditContainer />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/editVacation/:vacationId"
                    element={
                        <ProtectedRoute
                            user={user}
                            loading={loading}>
                            <VacationEditContainer />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/vacation/:vacationId"
                    element={
                        <ProtectedRoute
                            user={user}
                            loading={loading}>
                            <VacationDetailContainer />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/vacation/current"
                    element={
                        <ProtectedRoute
                            user={user}
                            loading={loading}>
                            <VacationCurrentContainer />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </>
    );
};

export default AppRouter;
