import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, Modal } from "@mui/material";
import { ActionEditCardModelProps } from "../models";
import {
    FerryActionEditCard,
    FlightActionEditCard,
    HotelActionEditCard,
    DriveActionEditCard,
    HikeActionEditCard,
    RestaurantActionEditCard,
    SightseeingActionEditCard
} from ".";

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            width: "60vw",
            "min-width": "280px",
            "max-width": "600px",
            "max-height": "60vh",
            overflow: "auto"
        },
        card_container: {
            display: "flex",
            "align-items": "center",
            "flex-direction": "column",
            "margin-top": "50px"
        }
    })
);

const ActionEditCard: FunctionComponent<ActionEditCardModelProps> = ({
    openModal,
    activity,
    selectedVacation,
    userId,
    selectedActivity,
    selectedDate,
    handleCloseModal,
    saveActivity,
    updateActivity,
    getActivityOverview
}) => {
    const classes = useStyles();

    const handleClose = (event: any, reason: string) => {
        if (reason && reason == "backdropClick") return;
        handleCloseModal();
    };

    return (
        <Modal
            className={classes.card_container}
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Card
                variant="outlined"
                sx={{ overflow: "auto" }}
                className={classes.card}>
                {activity === "ferry" && (
                    <FerryActionEditCard
                        selectedVacation={selectedVacation}
                        selectedActivity={selectedActivity}
                        userId={userId}
                        selectedDate={selectedDate}
                        handleCloseModal={handleCloseModal}
                        saveActivity={saveActivity}
                        updateActivity={updateActivity}
                        getActivityOverview={getActivityOverview}
                    />
                )}
                {activity === "flight" && (
                    <FlightActionEditCard
                        selectedVacation={selectedVacation}
                        selectedActivity={selectedActivity}
                        userId={userId}
                        selectedDate={selectedDate}
                        handleCloseModal={handleCloseModal}
                        saveActivity={saveActivity}
                        updateActivity={updateActivity}
                        getActivityOverview={getActivityOverview}
                    />
                )}
                {activity === "hotel" && (
                    <HotelActionEditCard
                        selectedVacation={selectedVacation}
                        selectedActivity={selectedActivity}
                        userId={userId}
                        selectedDate={selectedDate}
                        handleCloseModal={handleCloseModal}
                        saveActivity={saveActivity}
                        updateActivity={updateActivity}
                        getActivityOverview={getActivityOverview}
                    />
                )}
                {activity === "drive" && (
                    <DriveActionEditCard
                        selectedVacation={selectedVacation}
                        selectedActivity={selectedActivity}
                        userId={userId}
                        selectedDate={selectedDate}
                        handleCloseModal={handleCloseModal}
                        saveActivity={saveActivity}
                        updateActivity={updateActivity}
                        getActivityOverview={getActivityOverview}
                    />
                )}
                {activity === "hike" && (
                    <HikeActionEditCard
                        selectedVacation={selectedVacation}
                        selectedActivity={selectedActivity}
                        userId={userId}
                        selectedDate={selectedDate}
                        handleCloseModal={handleCloseModal}
                        saveActivity={saveActivity}
                        updateActivity={updateActivity}
                        getActivityOverview={getActivityOverview}
                    />
                )}
                {activity === "restaurant" && (
                    <RestaurantActionEditCard
                        selectedVacation={selectedVacation}
                        selectedActivity={selectedActivity}
                        userId={userId}
                        selectedDate={selectedDate}
                        handleCloseModal={handleCloseModal}
                        saveActivity={saveActivity}
                        updateActivity={updateActivity}
                        getActivityOverview={getActivityOverview}
                    />
                )}
                {activity === "sightseeing" && (
                    <SightseeingActionEditCard
                        selectedVacation={selectedVacation}
                        selectedActivity={selectedActivity}
                        userId={userId}
                        selectedDate={selectedDate}
                        handleCloseModal={handleCloseModal}
                        saveActivity={saveActivity}
                        updateActivity={updateActivity}
                        getActivityOverview={getActivityOverview}
                    />
                )}
            </Card>
        </Modal>
    );
};

export default ActionEditCard;
