import React, { FunctionComponent } from "react";
import "./App.css";
import AppRouter from "./router/AppRouter";

/**
 * Main app component
 *
 */
const App: FunctionComponent = () => {
    return (
        <div
            id="app"
            className="App">
            <AppRouter />
        </div>
    );
};

export default App;
