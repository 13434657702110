import create from "zustand";
import { User } from "firebase/auth";

interface State {
    user: User | undefined;
    setUser: (newUser: User) => void;
}

const useStore = create<State>((set) => ({
    user: undefined,
    setUser: (newUser: User) => set(() => ({ user: newUser }))
}));

export const UserStore = useStore;
