import React, { FunctionComponent, useEffect } from "react";
import { Typography, SpeedDial, SpeedDialAction, SpeedDialIcon, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import FlightIcon from "@mui/icons-material/Flight";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import HotelIcon from "@mui/icons-material/Hotel";
import HikingIcon from "@mui/icons-material/Hiking";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import StarRateIcon from "@mui/icons-material/StarRate";
import { PageContainer } from "../hoc";
import { VacationStore, UserStore, ActivityStore } from "../stores";
import { ActionEditCard, ActionViewCard, Dateline, ActionDeleteCard } from "../components";
import { Activity } from "../models";
import { DateTimeHelper } from "../helpers";

const useStyles = makeStyles(() =>
    createStyles({
        card_container: {
            display: "flex",
            "flex-direction": "row",
            gap: "1rem",
            "flex-wrap": "wrap",
            justifyContent: "space-between"
        },
        dateline_container: {
            flex: 1,
            display: "flex",
            overflow: "auto",
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
            "&::-webkit-scrollbar": {
                display: "none"
            }
        }
    })
);

const actions = [
    { icon: <FlightIcon />, name: "Flug", activity: "flight" },
    { icon: <HotelIcon />, name: "Unterkunft", activity: "hotel" },
    { icon: <DirectionsCarFilledIcon />, name: "Autofahrt", activity: "drive" },
    { icon: <HikingIcon />, name: "Wanderung", activity: "hike" },
    { icon: <DirectionsBoatIcon />, name: "Fähre", activity: "ferry" },
    { icon: <RestaurantIcon />, name: "Restaurants", activity: "restaurant" },
    { icon: <StarRateIcon />, name: "Aktivität", activity: "sightseeing" }
];

const VacationDetailContainer: FunctionComponent = () => {
    const classes = useStyles();
    const { vacationId } = useParams();
    const user = UserStore((state) => state.user);
    const selectedVacation = VacationStore((state) => state.selectedVacation);
    const getVacation = VacationStore((state) => state.getVacation);
    const resetVacation = VacationStore((state) => state.resetVacation);
    const activityOverview = ActivityStore((state) => state.activityOverview);
    const saveActivity = ActivityStore((state) => state.saveActivity);
    const updateActivity = ActivityStore((state) => state.updateActivity);
    const deleteActivity = ActivityStore((state) => state.deleteActivity);
    const getActivityOverview = ActivityStore((state) => state.getActivityOverview);
    const resetActivityOverview = ActivityStore((state) => state.resetActivityOverview);
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [activity, setActivity] = React.useState("");
    const [dates, setDates] = React.useState<string[]>([]);
    const [selectedDate, setSelectedDate] = React.useState("");
    const [selectedActivity, setSelectedActivity] = React.useState<Activity>();

    useEffect(() => {
        if (vacationId && user) {
            getVacation(user.uid, vacationId);
        }
    }, [vacationId, user, getVacation]);

    useEffect(() => {
        if (selectedVacation) {
            let dateIteration = new Date(selectedVacation.startDate);
            let dateString = selectedVacation.startDate;
            const tempDates = [dateString];
            const endDate = new Date(selectedVacation.endDate);
            do {
                dateIteration = new Date(dateIteration.setDate(dateIteration.getDate() + 1));
                dateString = dateIteration.toISOString().split("T")[0];
                tempDates.push(dateString);
            } while (dateIteration < endDate);
            setDates(tempDates);
            if (new Date() > new Date(selectedVacation.startDate) && new Date() < new Date(selectedVacation.endDate)) {
                setSelectedDate(new Date().toISOString().split("T")[0]);
            } else {
                setSelectedDate(selectedVacation.startDate);
            }
        }
    }, [selectedVacation]);

    useEffect(() => {
        if (!activityOverview && user && vacationId) {
            getActivityOverview(user.uid, vacationId);
        }
    }, [activityOverview, vacationId, user, getActivityOverview]);

    useEffect(() => {
        return () => {
            resetActivityOverview();
            resetVacation();
        };
    }, [resetActivityOverview, resetVacation]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleActionClick = (selectedActivity: string) => {
        setOpen(false);
        setOpenModal(true);
        setActivity(selectedActivity);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedActivity(undefined);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setSelectedActivity(undefined);
    };

    const showActivity = (activity: Activity) => {
        const tempSelectedDate = new Date(selectedDate);
        const startDate = new Date(activity.date);
        if (activity.endDate) {
            const endDate = new Date(activity.endDate);
            new Date(endDate.setDate(endDate.getDate() + 1));
            return activity.date === selectedDate || (startDate < tempSelectedDate && tempSelectedDate < endDate);
        }
        return activity.date === selectedDate;
    };

    return (
        <>
            {selectedVacation && user && (
                <PageContainer>
                    <Typography
                        variant="h3"
                        mt={2}
                        mb={2}
                        color="text.primary">
                        {selectedVacation.destination}
                    </Typography>
                    <div className={classes.dateline_container}>
                        {dates && (
                            <Dateline
                                dates={dates}
                                selectedDate={selectedDate}
                                setDate={setSelectedDate}
                            />
                        )}
                    </div>
                    {activityOverview && activityOverview.length > 0 && (
                        <>
                            {activityOverview.filter(showActivity).length === 0 && (
                                <Typography
                                    variant="h5"
                                    mt={2}
                                    mb={2}
                                    color="text.primary">
                                    Keine Aktivität für diesen Tag geplant
                                </Typography>
                            )}
                            <Grid
                                container
                                spacing={2}>
                                {activityOverview
                                    .filter(showActivity)
                                    .sort((activityA, activityB) =>
                                        DateTimeHelper.compareDatesTimes(activityA.date, activityA.time, activityB.date, activityB.time)
                                    )
                                    .map((activity, index) => (
                                        <Grid
                                            item
                                            key={index}
                                            xs={6}
                                            md={4}>
                                            <ActionViewCard
                                                key={activity.id}
                                                activity={activity}
                                                setSelectedActivity={setSelectedActivity}
                                                setActivity={setActivity}
                                                setOpenModal={setOpenModal}
                                                setOpenDeleteModal={setOpenDeleteModal}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </>
                    )}
                    {activityOverview && activityOverview.length === 0 && (
                        <Typography
                            variant="h5"
                            mt={2}
                            mb={2}
                            color="text.primary">
                            Keine Aktivität vorhanden
                        </Typography>
                    )}
                    <SpeedDial
                        ariaLabel="Event hinzufügen"
                        sx={{ position: "fixed", bottom: 72, right: 16 }}
                        icon={<SpeedDialIcon />}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}>
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipOpen
                                onClick={() => handleActionClick(action.activity)}
                            />
                        ))}
                    </SpeedDial>
                    <ActionEditCard
                        openModal={openModal}
                        activity={activity}
                        selectedVacation={selectedVacation}
                        userId={user.uid}
                        selectedActivity={selectedActivity}
                        selectedDate={selectedDate}
                        handleCloseModal={handleCloseModal}
                        saveActivity={saveActivity}
                        updateActivity={updateActivity}
                        getActivityOverview={getActivityOverview}
                    />
                    <ActionDeleteCard
                        openModal={openDeleteModal}
                        selectedActivity={selectedActivity}
                        selectedVacation={selectedVacation}
                        userId={user.uid}
                        deleteActivity={deleteActivity}
                        handleCloseModal={handleCloseDeleteModal}
                        getActivityOverview={getActivityOverview}
                    />
                </PageContainer>
            )}
        </>
    );
};

export default VacationDetailContainer;
