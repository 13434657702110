import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { PageContainer } from "../hoc";
import { VacationStore, UserStore } from "../stores";
import { VacationOverview } from "../components";
import { Vacation } from "../models";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            "margin-top": "20px"
        }
    })
);

const HomePageContainer: FunctionComponent = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const vacationOverview = VacationStore((state) => state.vacationOverview);
    const getVacationOverview = VacationStore((state) => state.getVacationOverview);
    const resetVacationOverview = VacationStore((state) => state.resetVacationOverview);
    const userState = UserStore((state) => state.user);
    const [pastVacation, setPastVacation] = useState<Vacation[]>([]);
    const [futureVacation, setFutureVacation] = useState<Vacation[]>([]);

    useEffect(() => {
        if (vacationOverview === null && userState) {
            getVacationOverview(userState.uid);
        } else if (vacationOverview) {
            const today = new Date();
            const tempPastVacation: Vacation[] = [];
            const tempFutureVacation: Vacation[] = [];
            vacationOverview.forEach((vacation) => {
                const vacationEnd = new Date(vacation.endDate);
                if (vacationEnd > today) {
                    tempFutureVacation.push(vacation);
                } else {
                    tempPastVacation.push(vacation);
                }
            });
            setPastVacation(tempPastVacation);
            setFutureVacation(tempFutureVacation);
        }
    }, [vacationOverview, userState, getVacationOverview]);

    useEffect(() => {
        return () => {
            resetVacationOverview();
        };
    }, [resetVacationOverview]);

    return (
        <PageContainer active={0}>
            {(!vacationOverview || vacationOverview.length === 0) && (
                <Typography
                    variant="h3"
                    mt={2}
                    mb={2}
                    color="text.primary">
                    Kein Urlaub gefunden
                </Typography>
            )}
            {futureVacation.length > 0 && (
                <VacationOverview
                    title="Geplante Urlaube"
                    vacationOverview={futureVacation}
                />
            )}
            <Button
                size="small"
                variant="outlined"
                sx={{ marginTop: "16px" }}
                className={classes.button}
                onClick={() => navigate("/editVacation")}>
                Urlaub erstellen
            </Button>
            {pastVacation.length > 0 && (
                <VacationOverview
                    title="Vergangene Urlaube"
                    vacationOverview={pastVacation}
                />
            )}
        </PageContainer>
    );
};

export default HomePageContainer;
