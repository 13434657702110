import ApiService from "./ApiService";
import { Activity } from "../models";

/**
 * Assessment Service
 * This service is responsible for save the assessment
 */
const ActivityService = {
    async getActivityOverview(uid: string, vacationId: string): Promise<Activity[]> {
        return ApiService.getData(`${uid}/vacation/${vacationId}/activities`);
    },

    async deleteActivity(uid: string, vacationId: string, activityId: string): Promise<boolean | void> {
        if (activityId && activityId !== "") {
            return ApiService.deleteData(`${uid}/vacation/${vacationId}/activities/${activityId}`);
        }
    },

    async postActivity(uid: string, vacationId: string, activity: Activity): Promise<boolean | void> {
        return ApiService.postData(`${uid}/vacation/${vacationId}/activities`, activity);
    },

    async updateActivity(uid: string, vacationId: string, activityId: string, activity: Activity): Promise<boolean | void> {
        return ApiService.updateData(`${uid}/vacation/${vacationId}/activities/${activityId}`, activity);
    },

    async getActivityById(uid: string, vacationId: string, id: string): Promise<any> {
        return ApiService.getData(`${uid}/vacation/${vacationId}/activities/${id}`);
    }
};

export default ActivityService;
