import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import { logout } from "../base";

interface PageContainerModel {
    children: any;
    active: number;
}

const PageContainer: any = ({ children, active }: PageContainerModel) => {
    const [value, setValue] = React.useState(active);
    const navigate = useNavigate();

    return (
        <>
            <Container
                maxWidth="md"
                sx={{ marginBottom: "72px" }}>
                {children}
            </Container>
            <Paper
                sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}>
                    <BottomNavigationAction
                        label="Home"
                        onClick={() => navigate("/Home")}
                        icon={<HomeIcon />}
                    />
                    <BottomNavigationAction
                        label="Logout"
                        onClick={() => logout()}
                        icon={<LogoutIcon />}
                    />
                </BottomNavigation>
            </Paper>
        </>
    );
};

export default PageContainer;
