import ApiService from "./ApiService";
import { Vacation } from "../models";

/**
 * Assessment Service
 * This service is responsible for save the assessment
 */
const VacationService = {
    async getVacationOverview(uid: string): Promise<Vacation[]> {
        return ApiService.getData(`${uid}/vacation`);
    },

    async deleteVacation(id: string): Promise<boolean | void> {
        if (id && id !== "") {
            return ApiService.deleteData(`vacation/${id}`);
        }
    },

    async postVacation(uid: string, vacation: Vacation): Promise<boolean | void> {
        return ApiService.postData(`${uid}/vacation`, vacation);
    },

    async updateVacation(uid: string, vacation: Vacation, vacationId: string): Promise<boolean | void> {
        return ApiService.updateData(`${uid}/vacation/${vacationId}`, vacation);
    },

    async getVacationById(uid: string, id: string): Promise<any> {
        return ApiService.getData(`${uid}/vacation/${id}`);
    }
};

export default VacationService;
