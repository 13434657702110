import React, { useState, FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import { CardActions, Button, TextField, Box } from "@mui/material";
import { sendPasswordReset } from "../base";
import { LoginCard } from "../hoc";

const useStyles = makeStyles(() =>
    createStyles({
        inputBox: {
            padding: "8px",
            display: "flex",
            "flex-direction": "column"
        }
    })
);

const ResetPageContainer: FunctionComponent = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");

    return (
        <LoginCard>
            <Box
                component="form"
                noValidate
                className={classes.inputBox}
                autoComplete="off">
                <TextField
                    margin="dense"
                    label="E-Mail"
                    variant="outlined"
                    color="secondary"
                    sx={{
                        input: { color: "#3e3e3e" },
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#32746d"
                            }
                        }
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Box>
            <CardActions>
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => sendPasswordReset(email)}>
                    Send Mail
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate("/")}
                    size="small">
                    to Login
                </Button>
            </CardActions>
        </LoginCard>
    );
};

export default ResetPageContainer;
