import React, { FunctionComponent } from "react";
import { Typography, Card, CardMedia, CardContent, CardActions, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DateTimeHelper } from "../helpers";
import { VacationOverviewProps } from "../models";

/**
 * Answer Question
 */
const VacationOverview: FunctionComponent<VacationOverviewProps> = ({ title, vacationOverview }) => {
    const navigate = useNavigate();

    return (
        <>
            <Typography
                variant="h3"
                mt={2}
                mb={2}
                color="text.primary">
                {title}
            </Typography>
            <Grid
                container
                spacing={2}>
                {vacationOverview
                    .sort((vacationA, vacationB) => DateTimeHelper.compareDates(vacationA.startDate, vacationB.startDate))
                    .map((vacation, index) => (
                        <Grid
                            item
                            key={index}
                            xs={12}
                            md={6}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    onClick={() => navigate(`/vacation/${vacation.id}`)}
                                    image={
                                        vacation.imageUrl !== ""
                                            ? vacation.imageUrl
                                            : "https://www.5vorflug.de/blog/wp-content/uploads/2018/05/punta-cana-Dom.-Republik.jpg"
                                    }
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        color="text.secondary"
                                        component="div">
                                        {vacation.destination}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary">
                                        {DateTimeHelper.toDateDisplay(vacation.startDate)} - {DateTimeHelper.toDateDisplay(vacation.endDate)}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        size="small"
                                        onClick={() => navigate(`/vacation/${vacation.id}`)}>
                                        Details
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={() => navigate(`/editVacation/${vacation.id}`)}>
                                        Bearbeiten
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
            </Grid>
        </>
    );
};
export default VacationOverview;
