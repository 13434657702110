import React, { useEffect, useState, FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { createStyles, makeStyles } from "@mui/styles";
import { CardActions, Button, TextField, Box, CardContent, Typography } from "@mui/material";
import { auth, logInWithEmailAndPassword } from "../base";
import { LoginCard } from "../hoc";

const useStyles = makeStyles(() =>
    createStyles({
        inputBox: {
            padding: "8px",
            display: "flex",
            "flex-direction": "column"
        }
    })
);

const LoginPageContainer: FunctionComponent = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading] = useAuthState(auth);
    const classes = useStyles();
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if (user) navigate("/home");
    }, [user, loading, navigate]);
    return (
        <LoginCard>
            <Box
                component="form"
                noValidate
                className={classes.inputBox}
                autoComplete="off">
                <TextField
                    margin="dense"
                    label="E-Mail"
                    variant="outlined"
                    color="secondary"
                    sx={{
                        input: { color: "#3e3e3e" },
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#32746d"
                            }
                        }
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="dense"
                    variant="outlined"
                    sx={{
                        input: { color: "#3e3e3e" },
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#32746d"
                            }
                        }
                    }}
                    label="Password"
                    type="password"
                    color="secondary"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Box>
            <CardActions>
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => logInWithEmailAndPassword(email, password)}>
                    Login
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate("/register")}
                    size="small">
                    Register
                </Button>
            </CardActions>
            <CardContent>
                <Typography variant="body2">
                    <Link to="/reset">Forgot Password</Link>
                </Typography>
            </CardContent>
        </LoginCard>
    );
};

export default LoginPageContainer;
