import create from "zustand";
import { Activity } from "../models";
import ActivityService from "../services/ActivityService";

interface State {
    selectedActivity: Activity | null;
    activityOverview: Activity[] | null;
    loadingActivities: boolean;
    loadingActivity: boolean;
    savingActivity: boolean;
    getActivityOverview: (uid: string, vacationId: string) => Promise<any>;
    getActivity: (uid: string, vacationId: string, activityId: string) => Promise<any>;
    saveActivity: (uid: string, vacationId: string, activity: Activity) => Promise<any>;
    updateActivity: (uid: string, vacationId: string, activityId: string, activity: Activity) => Promise<any>;
    deleteActivity: (uid: string, vacationId: string, activityId: string) => Promise<any>;
    resetActivity: () => void;
    resetActivityOverview: () => void;
}

const useStore = create<State>((set) => ({
    selectedActivity: null,
    activityOverview: null,
    loadingActivities: false,
    loadingActivity: false,
    savingActivity: false,
    getActivityOverview: async (uid: string, vacationId: string): Promise<any> => {
        let activities = await ActivityService.getActivityOverview(uid, vacationId);
        if (activities) {
            activities = Object.entries(activities).map((activity) => {
                return { ...activity[1], id: activity[0] };
            });
        } else {
            activities = [];
        }
        set(() => ({ activityOverview: activities }));
    },
    getActivity: async (uid: string, vacationId: string, activityId: string): Promise<any> => {
        let activity = await ActivityService.getActivityById(uid, vacationId, activityId);
        if (activity) {
            activity = { ...activity, id: activityId };
        } else {
            activity = null;
        }
        set(() => ({ selectedActivity: activity }));
    },
    saveActivity: (uid: string, vacationId: string, activity: Activity) => ActivityService.postActivity(uid, vacationId, activity),
    updateActivity: (uid: string, vacationId: string, activityId: string, activity: Activity) =>
        ActivityService.updateActivity(uid, vacationId, activityId, activity),
    deleteActivity: (uid: string, vacationId: string, activityId: string) => ActivityService.deleteActivity(uid, vacationId, activityId),
    resetActivity: () => set({ selectedActivity: null }),
    resetActivityOverview: () => set({ activityOverview: null })
}));

export const ActivityStore = useStore;
