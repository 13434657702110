import { db } from "../base";
import { ref, child, get, remove, push, set } from "firebase/database";

/**
 * Assessment Service
 * This service is responsible for save the assessment
 */
const ApiService = {
    async getData(path: string) {
        const dbRef = ref(db);
        return get(child(dbRef, path))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return snapshot.val();
                } else {
                    console.log("No data available");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    },
    async deleteData(path: string) {
        const dbRef = ref(db);
        return remove(child(dbRef, path))
            .then(() => {
                return true;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    async postData(path: string, data: any) {
        const dbRef = ref(db);
        return push(child(dbRef, path), data)
            .then(() => {
                return true;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    async updateData(path: string, data: any) {
        const dbRef = ref(db);
        return set(child(dbRef, path), data)
            .then(() => {
                return true;
            })
            .catch((error) => {
                console.error(error);
            });
    }
};

export default ApiService;
